import {
  Col,
  Container,
  Nav,
  NavItem,
  Navbar,
  NavLink,
  NavbarBrand,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import "../styles/Layout.scss";

const Layout = ({ children }) => {
  return (
    <div>
      <header className="layout-header">
        <Container>
          <Navbar expand="md">
            <NavbarBrand href="/">
              <img src="/focus-d_logo.png" alt="Adesso Logo" />
            </NavbarBrand>

            <Nav justified pills>
              <NavItem>
                <NavLink href="/" className="layout-navlink">
                  Начало
                </NavLink>
              </NavItem>
              <NavItem>
                <UncontrolledDropdown inNavbar>
                  <DropdownToggle
                    caret
                    className="layout-navlink-dropdown-button"
                  >
                    Изследвания
                  </DropdownToggle>
                  <DropdownMenu right style={{ backgroundColor: "#f5eae6" }}>
                    <DropdownItem
                      href="/examinations/xray"
                      className="layout-navlink"
                    >
                      Рентген
                    </DropdownItem>
                    <DropdownItem
                      href="/examinations/scanner"
                      className="layout-navlink"
                    >
                      Скенер
                    </DropdownItem>
                    <DropdownItem
                      href="/examinations/ultrasound"
                      className="layout-navlink"
                    >
                      Ехограф
                    </DropdownItem>
                    <DropdownItem
                      href="/examinations/mammograph"
                      className="layout-navlink"
                    >
                      Мамограф
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </NavItem>
              <NavItem>
                <NavLink href="/team" className="layout-navlink">
                  Екип
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/contacts" className="layout-navlink">
                  Контакти
                </NavLink>
              </NavItem>
              {/* <LanguagePicker /> */}
            </Nav>
          </Navbar>
        </Container>
        <hr
          style={{
            color: "#6c6c6c",
            height: "1rem",
            backgroundColor: "#6c6c6c",
            marginTop: "0",
          }}
        />
      </header>

      <Container
        style={{
          paddingBottom: "20px",
          width: "80rem",
          borderLeft: "solid 2px #c48c8c",
          borderRight: "solid 2px #c48c8c",
          minHeight: "80vh",
        }}
      >
        {children}
      </Container>
      <hr
        style={{
          color: "#6c6c6c",
          height: "1rem",
          backgroundColor: "#6c6c6c",
          marginBottom: "0",
        }}
      />
      <footer
        style={{
          textAlign: "center",
          backgroundColor: "#f5eae6",
          height: "5rem",
        }}
      >
        <Container fluid>
          <Row>
            <Col xs="12">
              <p>&copy; {new Date().getFullYear()} Фокус-Д</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default Layout;
