import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";

import Home from "./pages/Home";
import ExaminationsXray from "./pages/ExaminationsXray";
import ExaminationsScanner from "./pages/ExaminationsScanner";
import ExaminationsUltrasound from "./pages/ExaminationsUltrasound";
import ExaminationsMammograph from "./pages/ExaminationsMammograph";
import Team from "./pages/Team";
import Contacts from "./pages/Contacts";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/examinations/xray",
      element: <ExaminationsXray />,
    },
    {
      path: "/examinations/scanner",
      element: <ExaminationsScanner />,
    },
    {
      path: "/examinations/ultrasound",
      element: <ExaminationsUltrasound />,
    },
    {
      path: "/examinations/mammograph",
      element: <ExaminationsMammograph />,
    },
    {
      path: "/team",
      element: <Team />,
    },
    {
      path: "/contacts",
      element: <Contacts />,
    },
  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
