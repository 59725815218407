import Layout from "../layouts/Layout";
import { Row } from "reactstrap";
import "../styles/ExaminationsStyle.scss";

function ExaminationsXray() {
  return (
    <Layout>
      <Row className="text-container-header">Рентгенови услуги във Фокус-Д</Row>

      <Row style={{ margin: "2rem" }}>
        <Row className="text-container">
          Във Фокус-Д, ние сме оборудвани с последно поколение дигитален
          рентгенов апарат New-Oriental 1000-WDM, предназначен да осигури
          изключителна диагностична прецизност. Тази най-съвременна система
          осигурява изображения с висока резолюция, които позволяват поставянето
          на точни диагнози, като същевременно гарантира минимално облъчване на
          пациентите, спазвайки най-високите стандарти за безопасност.
        </Row>
        <Row className="text-container">
          Рентгенографията, по-известна като рентгенова снимка, е техника за
          образна диагностика, която използва рентгенови лъчи за получаване на
          подробни изображения на различни структури в човешкото тяло. Тя е
          крайъгълен камък на съвременната диагностична медицина, като играе
          жизненоважна роля при диагностицирането и наблюдението на широк
          спектър от състояния, включително:
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• травми и счупвания
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• тумори, кисти и други обемни аномалии
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• дегенеративни заболявания на мускулно-скелетната система
        </Row>
        <Row className="bullet-text-container">&emsp;&emsp;• инфекции</Row>
        <Row className="text-container">
          Информацията, получена чрез рентгенография, се включва широко в
          диагностичния процес в почти всички области на медицината. Нейната
          универсалност и надеждност я правят основен инструмент както за
          клинична диагностика, така и за изследователски цели.
        </Row>

        <Row className="text-container-mid-header">
          Приоритет на безопасността на пациентите
        </Row>
        <Row className="text-container">
          Рентгенографията винаги трябва да се извършва по лекарска препоръка,
          като се гарантира, че ползите са много повече от потенциалните
          рискове. Във Фокус-Д следваме строги протоколи за опазване на здравето
          на пациентите, включително:
        </Row>

        <Row className="bullet-text-container">
          &emsp;&emsp;• Използване на специализирани техники за свеждане до
          минимум на радиационното облъчване.
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Ограничаване на броя на направените изображения до
          абсолютно необходимите.
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Избягване на повторни прегледи, когато това е възможно.
        </Row>

        <Row className="text-container-mid-header">
          Цени на рентгенови изследвания:
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Рентгенография /една област/ - 30лв.
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Full leg ,Full spine - рентгенография на цял долен
          крайник или гръбначен стълб- 70лв.
        </Row>
      </Row>
    </Layout>
  );
}

export default ExaminationsXray;
