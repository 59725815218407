import Layout from "../layouts/Layout";
import "../styles/ExaminationsStyle.scss";

import { Row } from "reactstrap";

function ExaminationsUltrasound() {
  return (
    <Layout>
      <Row className="text-container-header">
        Ултразвукови изследвания във Фокус-Д
      </Row>

      <Row style={{ margin: "2rem" }}>
        <Row className="text-container">
          Ултразвукът или ултрасонографията е бърз, много точен и напълно
          безопасен диагностичен метод, широко използван за изследване на тъкани
          и меки структури в тялото. Неинвазивният му характер и липсата на
          странични ефекти го правят идеален за последващи оценки, провеждани
          през кратки интервали от време, като гарантират безопасността и
          комфорта на пациента.
        </Row>
        <Row className="text-container">
          Във Фокус-Д се гордеем, че предлагаме модерни диагностични услуги,
          използвайки най-новия ултразвуков апарат SonoScape P20 Elite. Този
          апарат от висок клас поставя нов стандарт в областта на изображенията,
          като осигурява несравнима разделителна способност за детайлни
          изследвания на тъканите, дори на значителна дълбочина. Неговите
          най-съвременни характеристики включват:
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Доплерова ехография за прецизна оценка на кръвния поток
          и състоянието на кръвоносните съдове.
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Еластография - усъвършенствана технология, която оценява
          еластичността и плътността на тъканите, предоставяйки информация,
          която се доближава до реалните физични свойства.
        </Row>
        <Row className="text-container">
          Нашите ултразвукови услуги покриват широк спектър от диагностични
          нужди, включително:
        </Row>

        <Row className="bullet-text-container">
          &emsp;&emsp;• Коремни органи
        </Row>
        <Row className="bullet-text-container">&emsp;&emsp;• Тестиси</Row>
        <Row className="bullet-text-container">&emsp;&emsp;• Млечни жлези</Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Цервикална област
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Повърхностни структури
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Кръвоносни съдове
        </Row>

        <Row className="text-container">
          Това, което ни отличава, е не само най-модерното ни оборудване, но и
          опитът на нашия специалист, който има над 20 години опит в
          провеждането на ултразвукови изследвания. С дълбоко познаване на
          техниките за изобразяване и диагностична прецизност, гарантираме, че
          получавате най-високия стандарт на грижи, съчетавайки напредничави
          технологии с експертна интерпретация.
        </Row>

        <Row className="text-container">
          Когато избирате Фокус-Д, вие избирате услуга, която дава приоритет на
          точността, безопасността и благополучието на пациента. Изпитайте
          увереността, че получавате диагностични грижи от световна класа, още
          днес.
        </Row>

        <Row className="text-container-mid-header">
          Ехографски преглед /една област/ - 50лв
        </Row>
      </Row>
    </Layout>
  );
}

export default ExaminationsUltrasound;
