import Layout from "../layouts/Layout";
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  CardGroup,
} from "reactstrap";
import rozalina from "../images/rozalina.jpeg";
import tsvetan from "../images/tsvetan.jpeg";
import jordanka from "../images/jordanka.jpeg";
import teodora from "../images/teodora.jpeg";
import ekip from "../images/ekip.jpeg";
import "../styles/Team.scss";

function Team() {
  return (
    <Layout>
      <>
        <Card>
          <CardBody>
            <CardTitle tag="h5" className="team-card-title">
              Запознайте се с нашия експертен екип от специалисти по образна
              диагностика.
            </CardTitle>
            <CardImg
              alt="Card image cap"
              bottom
              src={ekip}
              className="team-image"
            />
            <CardText>
              &emsp;&emsp;В нашата лаборатория за образна диагностика се гордеем
              с екип от високомотивирани професионалисти. Нашият персонал
              притежава богат опит, който гарантира, че всеки пациент получава
              възможно най-високо качество на услугите по образна диагностика.
            </CardText>
            <CardText>
              &emsp;&emsp;Екипът ни се състои от квалифицирани рентгенолози,
              рентгенови лаборанти и помощен персонал, всеки от които има
              дългогодишен практически опит в областта на образната
              диагностика,което им позволява да се справят с широк спектър от
              техники за образна диагностика - от рутинни рентгенови снимки до
              сложни компютърни томографии и ултразвукови изследвания - с
              прецизност и внимание към детайлите.
            </CardText>
            <CardText>
              &emsp;&emsp;Преди всичко нашият екип се ръководи от споделения
              ангажимент за грижа, насочена към пациента. Те разбират влиянието,
              което точните и навременни образни изследвания могат да окажат
              върху здравословното състояние на пациента, и са мотивирани от
              искреното желание да допринесат за благополучието на всеки
              пациент. Във всяко взаимодействие, от момента на пристигането Ви
              до предаването на резултатите, ще изпитате отдадеността, която е в
              основата на нашия екип.
            </CardText>
            <CardTitle tag="h5" className="team-card-footer">
              Изключително обслужване чрез експертност и прецизност.
            </CardTitle>
          </CardBody>
        </Card>
        <Card style={{ marginTop: "1.5rem " }}>
          <CardBody>
            <CardTitle tag="h5" className="doctor-card-title">
              Д-р Цветан Тодоров – управител, специалист по образна диагностика.
            </CardTitle>
            <CardText>
              Завършил Медицински университет в гр. Плевен през 1992г.
            </CardText>
            <CardText>
              Придобита специалност по Образна диагностика в Медицински
              Университет София през 1996г.
            </CardText>
            <CardText>
              През 2004г. придобива образователна степен Магистър по икономика
              специалност Здравен мениджмънт.
            </CardText>
            <CardText>
              &emsp;&emsp;• От 1992г. до 2009г. отделение по образна диагностика
              в МБАЛ „Д-р Стамен Илиев” гр. Монтана, като от 1999г. до 2009г.
              изпълнява длъжността Началник отделение
            </CardText>
            <CardText>
              &emsp;&emsp;• От 2009г. до 2014г. изпълнителен директор на МБАЛ „
              Д-р Стамен Илиев” гр. Монтана
            </CardText>
            <CardText>
              &emsp;&emsp;• 2014г. до 2024г. МБАЛ Сити клиник „Св. Георги” гр.
              Монтана- отделение по образна диагностика
            </CardText>
            <CardText>
              &emsp;&emsp;• 2019г. работил и специализирал в Бюргершпитал
              Солотурн Швейцария
            </CardText>
            <CardText>
              &emsp;&emsp;• През 2020г. основава и досега управлява СМДЛ „Фокус-
              Д”
            </CardText>
            <CardImg
              alt="Card image cap"
              bottom
              src={tsvetan}
              className="doctor-image"
            />
          </CardBody>
        </Card>
        <CardGroup>
          <Card className="secondary-card">
            <CardBody>
              <CardTitle tag="h5">
                Розалина Ангелова - рентгенов лаборант / Медицински университет
                Плевен/
              </CardTitle>
              <CardImg
                alt="Card image cap"
                bottom
                src={rozalina}
                className="secondary-image-left"
              />
            </CardBody>
          </Card>
          <Card className="secondary-card">
            <CardBody>
              <CardTitle tag="h5">
                Теодора Русинова - рентгенов лаборант / Медицински университет
                София/
              </CardTitle>
              <CardImg
                alt="Card image cap"
                bottom
                src={teodora}
                className="secondary-image"
              />
            </CardBody>
          </Card>
          <Card className="secondary-card-right">
            <CardBody>
              <CardTitle tag="h5" className="secondary-card-right-title">
                Йорданка Иванова - регистратор
              </CardTitle>
              <CardImg
                alt="Card image cap"
                bottom
                src={jordanka}
                className="secondary-image"
              />
            </CardBody>
          </Card>
        </CardGroup>
      </>
    </Layout>
  );
}
export default Team;
