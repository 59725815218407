import Layout from "../layouts/Layout";
import { Row, Col } from "reactstrap";
import "../styles/Contacts.scss";

function Contacts() {
  return (
    <Layout>
      <Row>
        <Col className="contacts-text-container">
          <Row className="contacts-text-container-header">Работно време:</Row>
          <Row className="contacts-text-container-body">
            В делнични дни - 08:30 - 17:00
          </Row>
          <Row className="contacts-text-container-header">
            Телефон за контакти:
          </Row>
          <Row className="contacts-text-container-body">+359 878424298</Row>
          <Row className="contacts-text-container-header">E-mail:</Row>
          <Row className="contacts-text-container-body ">info@focus-r.com</Row>
        </Col>
        <Col className="contacts-map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d724.6444887769846!2d23.222546269712!3d43.40674999819471!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40ab3357bdcbea8f%3A0x5a76b5702e2c5dd8!2sMontana%20Center%2C%20ul.%20%22Tzar%20Boris%20III%22%2011%D0%90%2C%203400%20Montana!5e0!3m2!1sen!2sbg!4v1732999060694!5m2!1sen!2sbg"
            width="800"
            height="650"
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </Col>
      </Row>
    </Layout>
  );
}

export default Contacts;
