import Layout from "../layouts/Layout";
import { Row } from "reactstrap";
import "../styles/ExaminationsStyle.scss";

function ExaminationsMammograph() {
  return (
    <Layout>
      <Row className="text-container-header">
        Мамографски услуги във Фокус-Д
      </Row>

      <Row style={{ margin: "2rem" }}>
        <Row className="text-container">
          Във Фокус-Д, ние сме оборудвани с мамографски апарат от последно
          поколение - Navigator DR Care-Sino MDT, който предлага прецизни,
          удобни и усъвършенствани изображения за здравето на млечните жлези.
          Нашият най-съвременен апарат извършва дигитална мамография в цялото
          поле (FFDM) , технология със златен стандарт за детайлна визуализация
          на структурите на млечната жлеза. Основните характеристики включват:
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Автоматизирано позициониране: Бързо, ергономично и
          автоматизирано за удобство на пациента.
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• ААвтоматичен контрол на експозицията: Регулира
          настройките в зависимост от плътността и дебелината на гърдата, като
          елиминира необходимостта от повторни експозиции.
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Функция E-Contrast: Електронно подобряване на контраста
          за по-добра яснота на изображението.
        </Row>
        <Row className="text-container">
          Комбинацията от тези функции осигурява висококачествени изображения с
          минимален дискомфорт и оптимална безопасност за пациентите.
        </Row>
        <Row className="text-container-mid-header">
          Защо да изберете мамографията?
        </Row>
        <Row className="text-container">
          Мамографията е единственият международно признат метод за ранно
          откриване на рак на гърдата. Тя дава възможност за идентифициране на
          аномалии, като например нови лезии, които може да са твърде малки, за
          да бъдат открити чрез самопреглед.
        </Row>

        <Row className="text-container">
          Мамографията може да се използва както за скрининг /при жени без
          симптоми/, така и за диагностика /при наличие на оплаквания/ в
          зависимост от целта на изследването.
        </Row>
        <Row className="text-container">
          Скрининговите мамографии се извършват при жени, които нямат оплаквания
          или видими промени в гърдите. Прилага се при жени в определена
          възрастова група, при които рискът е висок, а вредата е незначителна в
          сравнение с ползите.
        </Row>
        <Row className="text-container">
          Кои симптоми трябва да познаваме и целенасочено да наблюдаваме?
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Образуване на „бучка“ в гърдата или подмишницата, която
          може да се усети.
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Появата на спонтанно отделяне на секрет от гърдата,
          особено кървав или обилен.
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Промени в кожата или зърното на гърдата/ зачервяване ,
          пигментация, бучка, хлътване.
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Сърбеж и/или болка.
        </Row>
        <Row className="text-container">
          При жените над 45-годишна възраст, е необходимо да се прави
          специализиран преглед ВСЯКА ГОДИНА, включително мамографски
          изследвания. За предпочитане е прегледът да се извършва след
          менструация /между 7-ия и 15-ия ден от месечния цикъл/. Предишните
          образни изследвания трябва да бъдат представени за сравнение.
        </Row>
        <Row className="text-container">
          Структурата на гърдата може да не позволява категорично изключване на
          заболяване чрез мамография /най-често поради високата й плътност,
          наличие на импланти или след операция/. В тези случаи е необходимо
          допълнително изследване /най-често ултразвуково/.
        </Row>
        <Row className="text-container">
          Наличието на импланти в гърдата не изключва възможността за
          мамография, но винаги има малки „скрити зони“ и в тези случаи е
          по-добре изследването да се допълни с ултразвук.
        </Row>
        <Row className="text-container">
          Ултразвуковото изследване на млечните жлези е за предпочитане при
          пациентки във възрастовата група преди 45 години поради физиологичните
          особености в структурата на млечната жлеза ,както и липсата на
          йонизиращо лъчение .
        </Row>

        <Row className="text-container-mid-header">
          Цена на изследването - 70лв.
        </Row>
      </Row>
    </Layout>
  );
}

export default ExaminationsMammograph;
