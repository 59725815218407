import slide_1 from "./images/slide_1.jpeg";
import slide_2 from "./images/slide_2.jpeg";
import slide_3 from "./images/slide_3.jpeg";
import slide_4 from "./images/slide_4.jpeg";
import slide_5 from "./images/slide_5.jpeg";

const images = [
  {
    src: slide_1,
    key: "1",
  },
  {
    src: slide_2,
    key: "alt_2",
  },
  {
    src: slide_3,
    key: "alt_3",
  },
  {
    src: slide_4,
    key: "alt_4",
  },
  {
    src: slide_5,
    key: "alt_5",
  },
];

export default images;
