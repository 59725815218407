import Layout from "../layouts/Layout";
import ImageSlider from "../components/ImageSlider";
import { Row } from "reactstrap";
import "../styles/Home.scss";

function Home() {
  return (
    <Layout>
      <Row className="text-container-header">
        FOCUS-D - Прецизност в здравеопазването{" "}
      </Row>
      <Row className="container-first-paragraph">
        <Row className="text-container">
          От самото си създаване през 2020 г. FOCUS-D се ангажира да предоставя
          модерни, надеждни и достъпни диагностични услуги за нашите пациенти.
          Непрекъснатото ни развитие и инвестиции в най-новите диагностични
          технологии ни позволяват да отговаряме на най-високите стандарти в
          медицинската диагностика.
        </Row>
      </Row>
      <ImageSlider style={{ margin: "2rem" }}></ImageSlider>
      <Row style={{ margin: "2rem" }}>
        <Row className="text-container-bold">
          Нашите диагностични възможности:
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Дигитален рентген: Оборудван със специализиран софтуер
          за визуализация на целия гръбнак и долен крайник, което осигурява
          прецизен избор за лечение.
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Компютърен томограф /скенер/: Висока разделителна
          способност за цялостна диагностика на различни медицински нужди.
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Ултразвукови услуги /ехограф/: Предлагат се за коремни
          органи, щитовидна жлеза, млечни жлези, тестиси, шийна област и
          повърхностни структури.
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• ДДигитална мамография: Усъвършенствани мамографски
          изображения за ранна диагностика на рака на гърдата.
        </Row>

        <Row className="text-container-bold">Достъпност и удобство</Row>
        <Row className="text-container">
          Нашата цел е да осигурим бърза, ефективна и висококачествена
          диагностична помощ на всеки пациент. За всички диагностични услуги ,с
          изключение на ехографията ,не е необходимо предварително записване.
        </Row>
        <Row className="text-container-bold">Посветени на вашето здраве</Row>

        <Row className="text-container">
          За нашите пациенти се грижи екип от висококвалифицирани специалисти,
          които подхождат към всеки случай с индивидуално внимание и опит. Ние
          се стремим да осигурим точни резултати, които подпомагат вземането на
          информирани решения в областта на здравеопазването.
        </Row>
        <Row className="text-container">
          Като надежден доставчик на здравни услуги медико-диагностичната
          лаборатория FOCUS-D работи с Националната здравноосигурителна каса
          (НЗОК), като прави качествената диагностика достъпна за всички.
        </Row>
        <Row className="text-container-bottom">
          FOCUS-D - Поставяме нови стандарти за отлична диагностика
        </Row>
      </Row>
    </Layout>
  );
}

export default Home;
