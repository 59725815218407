import Layout from "../layouts/Layout";
import { Row } from "reactstrap";
import "../styles/ExaminationsStyle.scss";

function ExaminationsScanner() {
  return (
    <Layout>
      <Row className="text-container-header">
        Нискодозова компютърна томография (КТ)
      </Row>

      <Row style={{ margin: "2rem" }}>
        <Row className="text-container">
          Горди сме, че предлагаме усъвършенствани услуги за обработка на
          изображения с компютърния томограф GE BrightSpeed, най-съвременното
          устройство на GE Healthcare. Тази машина от последно поколение
          обединява възможностите на многодетекторната компютърна томография с
          компактното удобство на по-малките системи, което я прави
          забележителен избор за висококачествена образна диагностика. С
          технологии като HiLight Matrix Detector и Volara Digital Acquisition
          System, той значително намалява шума и повишава разделителната
          способност за изключителна яснота на диагностичните резултати.
        </Row>
        <Row className="text-container-mid-header">
          Ключови характеристики на компютърния томограф GE BrightSpeed:
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Ниска радиационна доза: BrightSpeed включва технологията
          Adaptive Statistical Iterative Reconstruction (ASiR), която намалява
          радиационната експозиция с до 50%, без да прави компромис с качеството
          на изображението.
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Широк обхват на приложение: Уредът поддържа цялостни
          компютърни томографии на глава, шия, гръден кош, корем, малък таз,
          гръбначен стълб и крайници, както и компютърна ангиография.
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Висока производителност: Системата осигурява бързо
          придобиване и обработка на изображения, задвижвана от 16-срезов
          детектор за точна и ефективна диагностика
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Компактен дизайн: Въпреки усъвършенстваните си
          възможности, BrightSpeed е проектирана за лесно интегриране в различни
          клинични условия.
        </Row>
        <Row className="text-container-mid-header">
          Безопасни и ориентирани към пациента грижи
        </Row>
        <Row className="text-container">
          Със своята най-съвременна технология BrightSpeed осигурява минимално
          излагане на радиация и е подходящ за широк спектър от клинични
          сценарии. Той е особено идеален за пациенти в спешни ситуации, при
          които се изисква незабавно и прецизно изобразяване. Извън спешните
          случаи бременността остава противопоказание, освен ако не се счита за
          абсолютно необходимо от лекар.
        </Row>

        <Row className="text-container">
          Тази компютърна томографска система повишава диагностичната увереност,
          като същевременно дава приоритет на безопасността и комфорта на
          пациентите, което я превръща в крайъгълен камък на съвременната
          образна диагностика. За запитвания или записвания, не се колебайте да
          се свържете с нас.
        </Row>
        <Row className="text-container-mid-header">
          Цени на компютърна томография /скенер/:
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Главен мозък, околоносни кухини - 150лв.
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Шиен отдел - 150лв.
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Прешлени /една област/ - 150лв.
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Таз ,стави и кости - 150лв.
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Бял дроб и медиастинум - 200лв.
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Корем - 200лв.
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Ангиография/съдова програма/ - 200лв.
        </Row>
        <Row className="bullet-text-container">
          &emsp;&emsp;• Контрастна материя и консумативи за контрастно
          изследване - 100лв.
        </Row>
      </Row>
    </Layout>
  );
}

export default ExaminationsScanner;
